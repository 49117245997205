import React from 'react';
import Seo from '../components/Seo';
import { PageProps } from '../typing/Page';
import { css, Global } from '@emotion/core';
import fonts from '../styles/fonts';
import reboot from '../styles/reboot';
import type from '../styles/type';
import utils from '../styles/utils';
import styled from '@emotion/styled';
import { fontSize, fontWeight, gutter, spacer, transition } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import Logo from '../components/Logo';
import ArrowRight from '../img/arrow_right.module.svg';

const StyledContainer = styled.div`
	width: 100%;
	padding: ${spacer['8']} ${gutter.full};
	margin: 0 auto;
	overflow: hidden;
	text-align: center;

	${breakpointUp('md')} {
		padding-right: ${gutter.md.full};
		padding-left: ${gutter.md.full};
	}

	${breakpointUp('xxxl')} {
		width: 50rem;
	}
`;

const StyledLogoContainer = styled.div`
	margin-bottom: ${spacer['10']};
`;

const StyledTitle = styled.h1`
	margin-bottom: ${spacer['8']};
	line-height: 1.15;
`;

const StyledText = styled.p`
	margin-bottom: 0;

	&:last-of-type {
		margin-bottom: ${spacer['8']};
	}
`;

const StyledLink = styled.a`
	display: inline-flex;
	align-items: center;
	font-weight: ${fontWeight.bold};
	font-size: ${fontSize.xs};

	&:hover {
		> svg {
			transform: translate(${spacer['2']}, 0);
		}
	}

	${breakpointUp('md')} {
		font-size: ${fontSize.sm};
	}

	> svg {
		margin-left: ${spacer['5']};
		transition: transform ${transition.hover.duration} ${transition.hover.timingFunction};
	}
`;

const NotFoundPage: React.FC<PageProps> = () => (
	<StyledContainer>
		<Global styles={() => css(fonts + reboot + type + utils)} />
		<Seo
			title="404: Stránka nenalezena/Not found"
			description="404: Stránka nenalezena/Not found"
			lang="cs"
		/>
		<StyledLogoContainer>
			<Logo />
		</StyledLogoContainer>
		<StyledTitle>
			Stránka nenalezena
			<br />
			Not found
		</StyledTitle>
		<StyledText>Stránka nebyla nalezena.</StyledText>
		<StyledText>The requested page was not found.</StyledText>
		<StyledLink href="/">
			homepage
			<ArrowRight />
		</StyledLink>
	</StyledContainer>
);

export default NotFoundPage;
